import React from "react";
import {usePage} from "@inertiajs/react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import InertiaTable from "laravel-inertia-react-table";

export default function Customers(props) {
    const { t,getLocales } = useLaravelReactI18n();
    const {customers} = usePage().props;

    return (<InertiaTable data={customers}/>);
}
