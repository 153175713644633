import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { usePage} from '@inertiajs/react';
import {useLaravelReactI18n} from "laravel-react-i18n";

const Process = () => {
    const { t, getLocales } = useLaravelReactI18n();

    return (
        <Container>
            Fertig
        </Container>
    );
};

export default Process;
