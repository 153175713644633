    import React from 'react';

const Create = () => {
return (
<div>
    <h1>Create Component for Test</h1>
</div>
);
}

export default Create;