import React, {useState} from "react";
import {router, usePage} from "@inertiajs/react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import InertiaTable from "laravel-inertia-react-table";
import {Button, ToggleButton} from "react-bootstrap";
import Icon from "@mdi/react";
import {mdiContentSave, mdiTrashCan} from "@mdi/js";

export default function Products(props) {
    const {t, getLocales} = useLaravelReactI18n();
    const {products} = usePage().props;
    const [sortMode, setSortMode] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const tableOptions = {selectable: false, showCheckbox: true};

    const handleSortButtonClick = () => {
        setSortMode(!sortMode);
    }
    const handleDeleteButtonClick = () => {

        if (window.confirm(t("Are you sure you want to :count products delete?",{'count':setSelectedItems.length}))) {
            router.post('product/delete/', {ids: selectedItems});
            setSelectedItems([]);
        }

    }
    const customToolbarButtons = (<>
        <ToggleButton type="checkbox"
                                                variant="secondary"
                                                checked={sortMode}
                                                value="1" onClick={handleSortButtonClick}>Sortieren</ToggleButton>
        <Button hidden={selectedItems.length===0} type="submit" className={'ms-2'} title={t('delete')} onClick={handleDeleteButtonClick}>
            <Icon path={mdiTrashCan} size={1}/>
        </Button>

    </>);


    const handleRowClick = (product) => {
        router.get('product/edit', {id: product.id});
    }

    return (<InertiaTable title={t('Products')} sortMode={sortMode} customToolbarButtons={customToolbarButtons}
                          data={products}
                          options={tableOptions}
                          onSelectedIdsChange={setSelectedItems}
                          selectedIds={selectedItems}
                          onRowClick={handleRowClick}
    />);
}
