import React, {useRef, useState} from "react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import {usePage, router} from "@inertiajs/react";
import {Container, Row, Col, Card, Form, ToggleButton, Button} from "react-bootstrap";
import ImportOptionsCsv from "./Import/ImportOptionsCsv.jsx";
import InertiaTable from "laravel-inertia-react-table";
import ImportOptions from "./Import/ImportOptions.jsx";
import ImportSelect from "./Import/ImportSelect.jsx";

export default function Import(props) {
    const {t, getLocales} = useLaravelReactI18n();
    const {importSource, importReady, importId, importItems, initialSelectedItems} = usePage().props;
    const [selectedItems, _setSelectedItems] = useState([]);
    const [onlyNew, setOnlyNew] = useState(false);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const selectedItemsRef = useRef(selectedItems); // Workarround für bug in Inertia
    // Aktualisieren Sie die ref jedes Mal, wenn setSelectedItems aufgerufen wird
    const setSelectedItems = (data) => {
        if (typeof data === 'function') {
            _setSelectedItems(prevState => {
                const updatedState = data(prevState);
                selectedItemsRef.current = updatedState;
                return updatedState;
            });
        } else {
            selectedItemsRef.current = data;
            _setSelectedItems(data);
        }
    };

    const handleOnlyNewButtonClick = () => {

        router.reload({
            only: ['only_new', 'importItems'],
            data: {only_new: !onlyNew},
        });
        setOnlyNew(!onlyNew);
    }
    const customToolbarButtons = (<ToggleButton type="checkbox"
                                                variant="secondary"
                                                checked={onlyNew}
                                                value="1" onClick={handleOnlyNewButtonClick}>nur neue</ToggleButton>);

    const handleAttributeChange = (event) => {
        const value = event.target.value;
        setSelectedAttributes(prevSelectedAttributes => {
            if (prevSelectedAttributes.includes(value)) {
                return prevSelectedAttributes.filter(attr => attr !== value);
            } else {
                return [...prevSelectedAttributes, value];
            }
        });
    };

    const handleStartImport = () => {
        router.post('import/run', {action: 'import', importId: importId, selectedItems: selectedItems,selectedAttributes: selectedAttributes});
    }

    let importOptions = null;
    switch (importSource) {
        case 'b2b':
        case 'b2c':
            importOptions = (<ImportOptions></ImportOptions>);
            break;
        case 'csv':
            importOptions = (<ImportOptionsCsv></ImportOptionsCsv>);
    }
    const handleOnChangeSource = (event) => {
        const value = event.target.value;
        router.reload({
            only: ['importSource'],
            data: {importSource: value},
        });
    }

    const ImportAttributeSelect = ({ selectedAttributes, onChangeAttribute }) => {
        return (
            <Form.Group>
                <Form.Label>{t('Select Import Attributes')}</Form.Label>
                <div>
                    {['title', 'sortorder', 'image', 'color_image', 'color_code'].map(attribute => (
                        <Form.Check
                            key={attribute}
                            inline
                            label={t(attribute)}
                            name="import-attribute"
                            type="checkbox"
                            id={`attribute-${attribute}`}
                            value={attribute}
                            checked={selectedAttributes.includes(attribute)}
                            onChange={onChangeAttribute}
                        />
                    ))}
                </div>
            </Form.Group>
        );
    };


    return (<>
            <div className="mt-5 d-flex">
                <h1>{t('Import')}</h1>
            </div>
            <Container>
                <ImportSelect></ImportSelect>
                <Card className={'mb-3'}>
                    <Card.Header>
                        {t('Import Source')}
                    </Card.Header>
                    <Card.Body>
                        <Form.Check
                            inline
                            label="B2C"
                            name="import-source"
                            type="radio"
                            id="source-b2c"
                            value="b2c"
                            disabled={importItems !== undefined}
                            checked={importSource === 'b2c'}
                            onChange={handleOnChangeSource}
                        />
                        <Form.Check
                            inline
                            label="B2B"
                            name="import-source"
                            type="radio"
                            id="source-b2b"
                            value="b2b"
                            disabled={importItems !== undefined}
                            checked={importSource === 'b2b'}
                            onChange={handleOnChangeSource}
                        />
                        <Form.Check
                            inline
                            label="CSV"
                            name="import-source"
                            type="radio"
                            id="source-csv"
                            value="csv"
                            disabled={importItems !== undefined}
                            checked={importSource === 'csv'}
                            onChange={handleOnChangeSource}
                        />
                    </Card.Body>
                </Card>
                {importOptions ? <Card className={'mb-3'}>
                    <Card.Header>{t('Import Options')}</Card.Header>
                    <Card.Body>{importOptions}</Card.Body>
                </Card> : <></>}
                {importItems ? <Card className={'mb-3'}>
                    <Card.Header>SKUs</Card.Header>
                    <Card.Body>
                        <InertiaTable data={importItems} customToolbarButtons={customToolbarButtons}
                                      selectedIds={selectedItems} onSelectedIdsChange={setSelectedItems}
                                      options={{selectable: true, updateOnly: ['importItems']}}/>

                    </Card.Body>
                </Card> : <></>}
                {importItems && <Card>
                    <Card.Body className={'flex align-content-end'}>

                        <ImportAttributeSelect
                            selectedAttributes={selectedAttributes}
                            onChangeAttribute={handleAttributeChange}
                        />


                        <Button onClick={handleStartImport}>{t('start import')}</Button>
                    </Card.Body>
                </Card>}

            </Container>
        </>
    );
}
