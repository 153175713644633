import {Button} from "react-bootstrap";
import React from "react";
import {usePage} from "@inertiajs/react";

export default function HeaderNavExtraComponent(props) {
    const {runningState} = usePage().props;

    return (
        <Button variant={"danger"}>{runningState.break === true ? runningState.remain : "Pause"}</Button>);
}
