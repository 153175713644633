import React from 'react';
import { useLaravelReactI18n } from 'laravel-react-i18n';
import { usePage, router } from '@inertiajs/react';
import {Card, Col, Container, Row} from 'react-bootstrap';

const ImportSelect = () => {
    const { t } = useLaravelReactI18n();
    const { imports } = usePage().props;

    const handleCardClick = (id) => {
        router.reload({
            data: { importId: id },
        });
    };

    const sourceIds = {1:"B2C",2:"B2B",3:"CSV"};
    const limits =  {'1_week':"1 Woche",'2_weeks':"2 Wochen",'1_month':"1 Monat",'all':"Alle Produkte"};

    const resolveImportKey = (importItem) => {
        switch (importItem.source_id){
            case 1: case 2:
                return limits[importItem.import_key];
            case 3:
                return importItem.orig_filename;
        }
    };
    if (imports){
    return (
        <Row xs={1} md={6} className="g-4 pb-4">
            {imports.map((importItem) => (
                <Col key={importItem.id}>
                <Card
                    bg={'info'}
                    key={importItem.id}
                    style={{cursor:'pointer'}}
                    className="import-card h-100"
                    onClick={() => handleCardClick(importItem.id)}
                >
                    <Card.Body>
                        <Card.Title>{sourceIds[importItem.source_id]}</Card.Title>
                        <Card.Text>
                            <strong>{new Date(importItem.updated_at).toLocaleString()}</strong><br />
                            {resolveImportKey(importItem)}
                        </Card.Text>
                    </Card.Body>
                </Card>
                </Col>
            ))}
        </Row>
    );}
    else return false;
};

export default ImportSelect;
