    import React from 'react';

const Index = () => {
return (
<div>
    <h1>Index Component for Test</h1>
</div>
);
}

export default Index;