import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import {router, usePage} from '@inertiajs/react';
import {useLaravelReactI18n} from "laravel-react-i18n";

const ImportOptions = () => {
    const { t } = useLaravelReactI18n();

    const {importReady,importLimit} = usePage().props;

    const handleOnChangeSource = (event) => {
        const value = event.target.value;
        router.reload({
            data: {importLimit: value},
        });
    }

    return (
        <Container>
            <Row className="my-3">
                <Col>

                    <Form.Group controlId="importLimit">
                        <Form.Label>{t('import limit')}</Form.Label>
                        <Form.Control as="select" value={importLimit}
                                      onChange={handleOnChangeSource}>
                            <option value="1_week">{t('1 week')}</option>
                            <option value="2_weeks">{t('2 weeks')}</option>
                            <option value="1_month">{t('1 month')}</option>
                            <option value="6_month">{t('6 month')}</option>
                            <option value="all">{t('all')}</option>
                        </Form.Control>
                    </Form.Group>
                    Status: {importReady ? 'OK' : 'nicht bereit' }
                </Col>
            </Row>
        </Container>
    );
};

export default ImportOptions;
