import React from "react";
import {usePage} from "@inertiajs/react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import InertiaTable from "laravel-inertia-react-table"

export default function Tasks(props) {
    const { t,getLocales } = useLaravelReactI18n();
    const {tasks} = usePage().props;

    return (<InertiaTable data={tasks}/>);
}
