import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import {useForm, usePage} from '@inertiajs/react';
import {useLaravelReactI18n} from "laravel-react-i18n";

const ImportOptionsCsv = () => {
    const { t, getLocales } = useLaravelReactI18n();
    const { data, setData, post, progress } = useForm({
        fileName: null,
    })
    const {importReady} = usePage().props;
    const fileName = null;

    function submit(e) {
        e.preventDefault();
        post('/import/upload', { forceFormData: true });
    }

    return (
        <Container>
            <Row className="my-3">
                <Col>
                    <Form onSubmit={submit}>
                        <Form.Group controlId="formFile">
                            <Form.Label>Datei-Upload</Form.Label>
                            <Form.Control className="mb-2" type={"file"} id="fileName" onChange={e => setData({...data,fileName: e.target.files[0]})}/>
                            {fileName && (
                                <div>
                                    <p>Hochgeladene Datei: {fileName}</p>
                                </div>
                            )}
                            <Button type={"submit"}
                                variant="primary"
                            >
                                {t('Upload')}
                            </Button>
                        </Form.Group>
                    </Form>
                    Status: {importReady ? 'OK' : 'nicht bereit' }
                </Col>
            </Row>
        </Container>
    );
};

export default ImportOptionsCsv;
