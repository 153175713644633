import React from "react";
import {router, usePage} from "@inertiajs/react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import InertiaTable from "laravel-inertia-react-table";

export default function Orders(props) {
    const { t,getLocales } = useLaravelReactI18n();
    const {orders} = usePage().props;

    const handleRowClick = (order) => {
        console.log("row click");
        console.log(order);
        // Implement your event handling code here
        router.get('order/show', {id: order.id});
    }

    return (
        <InertiaTable
            data={orders} title={t('Orders')}
            onRowClick={handleRowClick} />
    );
}
