import React from "react";
import {router, usePage} from "@inertiajs/react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import InertiaTable from "laravel-inertia-react-table";
import {Button, ToggleButton} from "react-bootstrap";
import {mdiPlus, mdiUngroup} from "@mdi/js";
import Icon from "@mdi/react";

export default function Users(props) {
    const { t,getLocales } = useLaravelReactI18n();
    const {users} = usePage().props;

    const handleAddClick = () => {
        router.get('user/create');
    }

    const customToolbarButtons = (<Button variant="secondary" onClick={handleAddClick}><Icon path={mdiPlus} size={1}/></Button>);

    const handleRowClick = (user) => {
        router.get('user/edit', {id: user.id});
    }

    return (<InertiaTable data={users} customToolbarButtons={customToolbarButtons} onRowClick={handleRowClick}/>);
}
