import React, { useState } from 'react';
import { Inertia } from '@inertiajs/inertia';
import { Container, Card, Form, Row, Col, Button } from 'react-bootstrap';
import {useForm, usePage} from "@inertiajs/react";
import {useLaravelReactI18n} from "laravel-react-i18n";

function Edit() {
    const { t, getLocales } = useLaravelReactI18n();
    const {user} = usePage().props;
    const {data, setData, post, processing,progress,transform, errors} = useForm(user ? {
        name: user.name,
        email: user.email,
        group_id: user.group_id,
        password: '' // add here
    } : {
        name: '',
        email: '',
        group_id: 0,
        password: '' // add here
    });

    function handleChange(e) {
        const key = e.target.id;
        const value = e.target.value;
        setData({
            ...data,
            [key]: value,
        });
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (user) {
            post(`/user/${user.id}`, { forceFormData: true });
        } else {
            post(`/user`, { forceFormData: true });
        }
    }

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <Card>
                    <Card.Header className={"d-flex justify-content-between"}>
                        <h5>{t(user ? 'Edit User' : 'Create User')}</h5>
                        <Button variant="primary" type="submit">
                            {t('save')}
                        </Button>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Name
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    className={errors.name ? 'is-invalid' : ''}
                                    placeholder="Enter name"
                                    id="name"
                                    type="text"
                                    value={data.name}
                                    onChange={handleChange}
                                />
                                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Email
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    className={errors.email ? 'is-invalid' : ''}
                                    placeholder="Enter email"
                                    id="email"
                                    type="email"
                                    value={data.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Usergroup
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    as="select"
                                    className={errors.group_id ? 'is-invalid' : ''}
                                    id="group_id"
                                    value={data.group_id}
                                    onChange={handleChange}
                                >
                                    <option value="0">user</option>
                                    <option value="1">admin</option>
                                </Form.Control>
                                {errors.group_id && <div className="invalid-feedback">{errors.group_id}</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Password
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    className={errors.password ? 'is-invalid' : ''}
                                    placeholder="Enter password"
                                    id="password"
                                    type="password"
                                    value={data.password}
                                    onChange={handleChange}
                                />
                                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </Container>
    );
}
export default Edit;
