import './bootstrap';
import { createInertiaApp } from '@inertiajs/react'
import { createRoot } from 'react-dom/client'
import { Layout } from 'laravel-inertia-backend';
import './bootstrap';
import '../scss/app.scss';
import { LaravelReactI18nProvider } from 'laravel-react-i18n';
import menu from "./menu.js";
import {version} from "./version.js";
import HeaderNavExtraComponent from "./Components/HeaderNavExtraComponent.jsx";

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.jsx', { eager: true })
        let page = pages[`./Pages/${name}.jsx`]
        page.default.layout =
            name.startsWith("Auth/") || name == "Welcome"
                ? undefined
                : (page) => <Layout
                    menuEntries={menu}
                    sidebarBottomComponent={(<>Version: {version}</>)}
                    headerNavExtraComponent={<HeaderNavExtraComponent/>}
                    children={page} />;
        return page
    },
    setup({ el, App, props }) {
        createRoot(el).render(<LaravelReactI18nProvider
            locale={'de'}
            fallbackLocale={'en'}
            files={import.meta.glob('/resources/lang/*.json')}
        ><App {...props} />
        </LaravelReactI18nProvider>
        )
    },
})
