import React from "react";
import {Card, Spinner, ListGroup, ListGroupItem, Col, Row, Container, Table} from "react-bootstrap";
import {useLaravelReactI18n} from "laravel-react-i18n";
import {router, usePage} from "@inertiajs/react";
import * as imports from "react-bootstrap/ElementChildren";

export default function Dashboard(props) {
    const {t, getLocales} = useLaravelReactI18n();
    const {lastCronJobTime, ordersCount, ordersCountToday, orderErrorsCount, lastOrders} = usePage().props;

    const handleOrderClick = (order) => {
            router.get('order/show', {id: order.id});
    }
    return (<>
            <div className="mt-5 d-flex justify-content-center">
                <h1>{props.appname}</h1>
            </div>
            <div className="m-5">
                <Container>
                    <Row xs={1} md={4} className="g-4 pb-4">
                        <Col>
                            <Card bg={"secondary"} text="white" className="h-100">
                                <Card.Header><h6>{t('Cronjob')}</h6></Card.Header>
                                <Card.Body>
                                    {lastCronJobTime}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card bg={"primary"} text="white" className="h-100">
                                <Card.Header><h6>{t('Number of Orders')}</h6></Card.Header>
                                <Card.Body>
                                    {ordersCount}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card bg={"success"} text="white" className="h-100">
                                <Card.Header><h6>{t('Number of Orders Today')}</h6></Card.Header>
                                <Card.Body>
                                    {ordersCountToday}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card bg={"danger"} text="white" className="h-100">
                                <Card.Header><h6>{t('Number of Errors')}</h6></Card.Header>
                                <Card.Body>
                                    {orderErrorsCount}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card bg={"light"} className="h-100">
                                <Card.Header><h6>{t('Last orders')}</h6></Card.Header>
                                    <Table className="mb-0">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t('shop')}</th>
                                            <th>{t('customer name')}</th>
                                            <th>{t('date')}</th>
                                            <th>{t('state')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {lastOrders.map((order) => {
                                                return (<tr style={{cursor:'pointer'}} onClick={() => handleOrderClick(order)}>
                                                    <td>{order.shop_order_id}</td>
                                                    <td>{{1:'B2B',2:'B2C alt',3:'B2C'}[order.connection_id]}</td>
                                                    <td>{order.customer_name}</td>
                                                    <td>{order.order_date}</td>
                                                    <td>{t({0:'failed',1:'success'}[order.status])}</td>
                                                </tr>);
                                            }
                                        )}
                                        </tbody>
                                    </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
