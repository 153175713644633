
import React from "react";

export default function Welcome() {
    return (
        <div className="vh-100 d-flex align-items-center justify-content-center">
            <h1 className="text-center">Welcome to the Inertia website</h1>
        </div>
    );
}
