import { Inertia } from '@inertiajs/inertia';
import React, { useState } from 'react';

export default function Edit() {
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    zip_code: '', // Added PLZ-field (zip code)
    place: '', // Added Ortsfeld-field (place)
  });

  function handleChange(e) {
    const key = e.target.id;
    const value = e.target.value;
    setValues(values => ({
      ...values,
      [key]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    Inertia.post('/users', values);
  }

    function handleSubmit(e) {

        setState({ort:ort});
    }


    return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="first_name">First name:</label>
      <input id="first_name" value={values.first_name} onChange={handleChange} />
      <label htmlFor="last_name">Last name:</label>
      <input id="last_name" value={values.last_name} onChange={handleChange} />
      <label htmlFor="email">Email:</label>
      <input id="email" value={values.email} onChange={handleChange} />
      <label htmlFor="zip_code">PLZ:</label> {/* Added PLZ-field (zip code) */}
      <input id="zip_code" value={values.zip_code} onChange={handleChangePlz} />
      <label htmlFor="place">Ort:</label> {/* Added Ortsfeld-field (place) */}
      <input id="place" value={values.place} onChange={handleChange} />
      <button type="submit">Submit</button>
    </form>
  );
}
