    import React from 'react';

const Show = () => {
return (
<div>
    <h1>Show Component for Test</h1>
</div>
);
}

export default Show;