    import React from 'react';

const Edit = () => {
return (
<div>
    <h1>Edit Component for Test</h1>
</div>
);
}

export default Edit;